import { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify'

export default function useList(props) {

  const [items, setItems] = useState(props.items)
  const [count, setCount] = useState(0)
  const [totalItems, setTotalItems] = useState(props.items.length)
  const [continuationToken, setContinuationToken] = useState(props.continuationToken ?? false)
  const [pagesLoadedCount, setPagesLoadedCount] = useState(props.pagesLoadedCount ?? 0)
  const [isLoading, setIsLoading] = useState(false)
  const [isAssigningTables, setIsAssigningTables] = useState(false)
  const [banquetTableSize, setBanquetTableSize] = useState(10)
  const [displayTableSummary, setDisplayTableSummary] = useState(false)
  const [tableWarnings, setTableWarnings] = useState([])
  const [tableCount, setTableCount] = useState(0)
  const [tableCountVip, setTableCountVip] = useState(0)
  const [service, setService] = useState(false)
  const [isErrored, setIsErrored] = useState(false)
  const [moreData, setMoreData] = useState(true)

  function resetData() {
    setItems([])
    setTotalItems(0)
    setContinuationToken(null)
    setPagesLoadedCount(0)
  }

  useEffect(() => {
    Auth.currentSession()
      .then(() => { setService('list') })
      .catch(() => { setService('listUnauth') })
  }, [props.type])

  async function loadData() {

    if (!service) { return }
    if (isLoading) { return }

    let response
    if ((continuationToken || items.length === 0) && pagesLoadedCount < props.stopAfterPage) { //  && !isLoading

      // We don't want to request the same page as has already been requested

      const parameters = { queryStringParameters: {} }
      if (continuationToken) { parameters.queryStringParameters.c = continuationToken }
      if (props.pageSize) { parameters.queryStringParameters.p = Number(props.pageSize) }

      setIsErrored(false)
      setIsLoading(true)
      try {
        response = await API.get(service, props.source, parameters)
        setMoreData((Boolean(response.nextContinuationToken))) //  || response.items.length !== 0
        setItems(items.concat(response.items))
        setTotalItems(totalItems + response.items.length)
        setPagesLoadedCount(pagesLoadedCount + 1)
        setIsLoading(false)
        setContinuationToken(response.nextContinuationToken === '' ? false : response.nextContinuationToken)

      }
      catch (error) {
        console.log(error)
        setIsLoading(false)
        setIsErrored(true)
      }
    }
  }

  useEffect(() => {
    setCount(count + 1)
    if (service) {
      loadData()
    }
  }, [continuationToken, props.stopAfterPage, service])

  async function deleteItems(keys) {

    for (let key of keys) {
      API.del('list', props.source, { body: key })
        .catch(error => {
          console.log(error)
        })
    }
    const simpleKeys = keys.map(key => key.pk)
    setItems(items.filter(item => !simpleKeys.includes(item.pk)))

  }

  async function alignChapterMembers(keys) {

    for (let key of keys) {
      API.put("item", "/chapter/" + key.pk + "/alignChapterMembers", {})
    }
  }


  // ==========================
  // Banquet

  async function toggleAdminVip(id) {
    await API.put("item", "/banquet/" + id + "/toggleAdminVip")
  }

  async function setAdminValue(id, key, value) {
    let index = items.findIndex(item => item.pk === id)
    if (index !== -1) { items[index][key] = value }
    await API.put("item", "/badge/" + id + "/adminSetValue", { body: { key: key, value: value } })
  }

  async function setAdminRequestedTable(id, value) {
    let index = items.findIndex(item => item.pk === id)
    if (index !== -1) { items[index].requestedTable = value }
    await API.put("item", "/banquet/" + id + "/adminRequestedTable", { body: { value: value } })
  }

  async function setAssignedGroup(id, value) {
    let index = items.findIndex(item => item.pk === id)
    if (index !== -1) { items[index].assignedGroup = value }
    await API.put("item", "/banquet/" + id + "/assignedGroup", { body: { value: value } })
  }

  function tableAssignments(tableSize) {
    setBanquetTableSize(tableSize)
    setIsAssigningTables(true)
  }


  useEffect(() => {
    if (!isAssigningTables) { return }

    let warnings = []
    // Create an array for 400 tables (well above any reasonable maximum)
    let tableOccupancy = new Array(400)
    let groups = []
    for (let i = 0; i < tableOccupancy.length; i += 1) {
      tableOccupancy[i] = 0
    }

    // Remove all the current assignments
    for (let i = 0; i < items.length; i += 1) {
      items[i].table = null
    }

    // Create a list of VIP tables
    let vipTables = []

    // Assign the fixed VIP tables
    for (let i = 0; i < items.length; i += 1) {
      if (items[i].requestedTable) {
        items[i].table = items[i].requestedTable
        tableOccupancy[items[i].requestedTable] += 1

        if (!vipTables.includes(items[i].requestedTable)) {
          vipTables.push(items[i].requestedTable)
        }
      }
    }

    // Identify groups
    for (let i = 0; i < items.length; i += 1) {
      if (!items[i].requestedTable && items[i].assignedGroup) {
        let groupIndex = groups.findIndex(item => item.group === items[i].assignedGroup)
        if (groupIndex === -1) {
          groups.push({ group: items[i].assignedGroup, size: 1 })
        } else {
          groups[groupIndex].size += 1
        }
      }
    }

    // sort the group names to improve adjacency of the groups.
    groups.sort((a, b) => {
      if (a.group > b.group) { return 1 }
      if (a.group < b.group) { return -1 }
      return 0
    })

    // Assign groups to tables
    for (let i = 0; i < groups.length; i += 1) {
      // Find the first table with enough space
      // Make sure we don't choose table zero

      let firstTableWithSpace = tableOccupancy.findIndex((currentOccupancy, table) => (table > 0 && banquetTableSize - currentOccupancy >= groups[i].size && !vipTables.includes(table)))

      if (firstTableWithSpace === -1) {
        warnings.push('No table with sufficient space: ' + groups[i].group + ' has ' + groups[i].size + ' members')
      } else {
        // Go through all the attendees, find those with that group and assign them to the table
        for (let j = 0; j < items.length; j += 1) {
          if (items[j].assignedGroup === groups[i].group) {
            items[j].table = firstTableWithSpace
            tableOccupancy[firstTableWithSpace] += 1
          }
        }
      }
    }

    // Finally assign everyone else

    for (let j = 0; j < items.length; j += 1) {
      if (!items[j].table) {
        let firstTableWithSpace = tableOccupancy.findIndex((currentOccupancy, table) => (table > 0 && banquetTableSize - currentOccupancy >= 1 && !vipTables.includes(table)))
        items[j].table = firstTableWithSpace
        tableOccupancy[firstTableWithSpace] += 1
      }
    }

    setItems(items)
    setTotalItems(items.length)
    setIsAssigningTables(false)
    setTableWarnings(warnings)
    setTableCount(tableOccupancy.filter(i => i > 0).length)
    setTableCountVip(vipTables.length)
    setDisplayTableSummary(true)

  }, [isAssigningTables])



  return {
    isErrored, isLoading, moreData, items, pagesLoadedCount, resetData, continuationToken,
    deleteItems,
    alignChapterMembers,
    displayTableSummary, setDisplayTableSummary, tableWarnings, tableCount, tableCountVip,
    tableAssignments, isAssigningTables, toggleAdminVip, setAdminRequestedTable, setAssignedGroup, setAdminValue,
    totalItems
  }

}
