import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"

export default function GridControl(props) {

  const { list,
    viewType,
    availableFilterColumns,
    requestCsv,
    reloadToPage,
    initialStopAfterPage,
    setStopAfterPage,
    tableFilter, setTableFilter,
    tableFilterColumn, setTableFilterColumn,
    viewFavorites, setViewFavorites,
    viewRecents, setViewRecents,
    selectAll,
    newLink, newGuestLink, printBadges,
    newPayment,
    requestDelete,
    requestAlignChapterMembers,
    banquetTableSize,
    setBanquetTableSize,
    setDisplayTableSummary,
    configMode, setConfigMode } = props


  let [registrationStatus, setRegistrationStatus] = useState({})

  useEffect(() => {
    if (props.registrationStatus && props.registrationStatus.data) {
      setRegistrationStatus(props.registrationStatus.data)
    }
  }, [props.registrationStatus])


  function handleChange(event) {
    setTableFilter(event.target.value)
  }

  function clearFilter() {
    setTableFilter('')
  }

  function handleFilterColumnChange(event) {
    setTableFilterColumn(event.target.value)
  }

  function toggleConfigMode() {
    setConfigMode(!configMode)
  }

  function toggleViewFavorites() {
    setViewFavorites(!viewFavorites)
  }

  function toggleViewRecents() {
    setViewRecents(!viewRecents)
  }

  function handleBanquetTableSizeChange(event) {
    setBanquetTableSize(event.target.value)
    setDisplayTableSummary(false)
  }

  function handleRequestDelete() {
    /* eslint-disable no-alert */
    if (window.confirm('You are about to delete the selected items. This cannot be undone.')) {
      requestDelete()
    }
  }

  function handleRequestAlignBaseChapter() {
    if (window.confirm('Align Base and Chapter for Selected?. This cannot be undone.')) {
      requestAlignChapterMembers()
    }
  }

  // To Do: support denormlized column filters
  const filterColumn = availableFilterColumns.filter(col => col.key === tableFilterColumn)[0]
  const isBoolean = filterColumn?.fieldType === 'boolean' ?? false
  const isDisabled = list.isLoading

  if (configMode) {
    return (
      <div style={{ paddingBottom: 12 }}>
        <div style={{ float: 'left' }}>
          <div style={{ paddingBottom: 12 }}>
            <div style={{ float: "left" }}>
              <span style={{ paddingRight: 10 }}>
                <button
                  className="usa-tooltip"
                  title="Save View"
                  onClick={toggleViewFavorites}
                >
                  <i className="far fa-cloud-upload"></i>
                </button>
                <button
                  className="usa-tooltip"
                  title="Delete View"
                  onClick={toggleViewFavorites}
                >
                  <i className="far fa-trash-alt"></i>
                </button>
              </span>
            </div>
          </div>
        </div>
        <div style={{ float: 'right' }}>
          <ConfigTools toggleConfigMode={toggleConfigMode} />
        </div>

      </div>
    )
  }

  const style = { marginRight: 10, border: "1px solid silver", borderRadius: "0.25em", padding: "0.4em", background: '#eeeeee' }
  return (
    <div>
      <div>
        <div style={{ paddingBottom: 12 }}>
          <div style={{ float: 'left', display: 'contents' }}>
            <select
              className="usa-tooltip"
              title="Filter Column"
              onChange={handleFilterColumnChange}
              value={tableFilterColumn}
            >
              {availableFilterColumns.map(column =>
                <option key={column.key} value={column.key} >{column.label}</option>
              )}
            </select>
            {isBoolean ?
              <select
                className="usa-tooltip"
                title="Filter Value"
                onChange={handleChange}
                value={tableFilter}
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select> :
              <>
                <input
                  className="usa-tooltip"
                  title="Filter Value"
                  placeholder="Filter available items"
                  value={tableFilter}
                  onChange={handleChange}
                />
                <button
                  className="usa-tooltip"
                  title="Clear filter value"
                  onClick={clearFilter}
                >
                  <i className="far fa-times"></i>
                </button>
              </>
            }
            <span
              className="usa-tooltip"
              title="Items visible - Total items available"
              style={{ marginLeft: 6, paddingRight: 10, minWidth: 70, display: 'inline-block', cursor: 'pointer' }}
            >
              {list?.items.length}/{list.totalItems}
            </span>

            <span style={{ border: "1px solid silver", borderRadius: "0.25em", padding: "0.4em", background: '#eeeeee' }}>
              <button
                className="usa-tooltip usa-button--unstyled"
                title="Reload items"
                disabled={isDisabled}
                onClick={() => reloadToPage(initialStopAfterPage)}
              >
                <i className="fas fa-redo-alt fa-fw"></i>
              </button>
            </span>
            <span style={{ border: "1px solid silver", borderRadius: "0.25em", padding: "0.4em", background: '#eeeeee' }}>
              <button
                className="usa-tooltip usa-button--unstyled"
                title="Load more items"
                disabled={isDisabled}
                onClick={() => setStopAfterPage(list.pagesLoadedCount + 1)}
              >
                <i className="fas fa-arrow-right fa-fw"></i>
              </button>
            </span>
            <span style={{ marginRight: 10, border: "1px solid silver", borderRadius: "0.25em", padding: "0.4em", background: '#eeeeee' }}>
              <button
                className="usa-tooltip usa-button--unstyled"
                title="Load all items"
                disabled={isDisabled}
                onClick={() => setStopAfterPage(998)}
              >
                <i className="fas fa-arrow-to-right fa-fw"></i>
              </button>
            </span>

            <span style={{ marginRight: 10, border: "1px solid silver", borderRadius: "0.25em", padding: "0.4em", background: '#eeeeee' }}>
              <button
                className="usa-tooltip usa-button--unstyled"
                title="Open all items in CSV"
                disabled={isDisabled}
                onClick={requestCsv}
                style={{ textDecoration: 'none' }}
              >
                csv <i className="fal fa-file-csv fa-fw"></i>
              </button>
            </span>

            <span style={{ paddingRight: 10 }} />
            {list.isLoading &&
              <i className="fas fa-circle-notch fa-spin usa-button--unstyled" style={{ textDecoration: 'none' }}></i>
            }
            <span style={{ paddingRight: 10 }} />
            {list.isErrored &&
              <span style={{ color: '#b50908' }}><i className="fas fa-exclamation fa-fw"></i> An error occurred loading the data</span>
            }

          </div>
          <div style={{ float: 'right' }}>
            <ConfigTools toggleConfigMode={toggleConfigMode} />
          </div>
        </div>

      </div>
      <div>
        <div style={{ paddingBottom: 12 }}>
          <div style={{ float: 'left' }}>
            <span style={style}>
              <button
                className="usa-tooltip usa-button--unstyled"
                title="Toggle Recent"
                style={{ cursor: 'pointer', textDecoration: 'none' }}
                onClick={toggleViewRecents}
              >
                Toggle {viewRecents ? <i className="fas fa-clock fa-fw"></i> : <i className="far fa-clock"></i>}
              </button>
            </span>

            <span style={style}>
              <button
                className="usa-tooltip usa-button--unstyled"
                title="Toggle Selected"
                style={{ cursor: 'pointer', textDecoration: 'none' }}
                onClick={toggleViewFavorites}
              >
                Toggle {viewFavorites ? <i className="fas fa-star fa-fw"></i> : <i className="far fa-star"></i>}
              </button>
            </span>

            <span style={style}>
              <button
                className="usa-tooltip usa-button--unstyled"
                title="Select All Visible Items"
                style={{ cursor: 'pointer', textDecoration: 'none' }}
                onClick={() => selectAll(true)}
              >
                Select All
              </button>
            </span>

            <span style={style}>
              <button
                className="usa-tooltip usa-button--unstyled"
                title="Unselect All"
                style={{ cursor: 'pointer', textDecoration: 'none' }}
                onClick={() => selectAll(false)}
              >
                Unselect All
              </button>
            </span>
            {viewType !== 'view-banquet-seating' && viewType !== 'view-badge' &&
              <span style={style}>
                <button
                  className="usa-tooltip usa-button--unstyled"
                  title="Delete Selected"
                  style={{ cursor: 'pointer', textDecoration: 'none' }}
                  onClick={handleRequestDelete}
                >
                  Delete Selected <i className="fas fa-trash fa-fw"></i>
                </button>
              </span>
            }

            {viewType !== 'view-banquet-seating' && viewType !== 'view-badge' && viewType !== 'view-registration' &&
              <span style={{ marginRight: 10, border: "1px solid silver", borderRadius: "0.25em", padding: "0.4em", background: '#eeeeee' }}>
                <Link
                  className="usa-tooltip usa-button--unstyled"
                  title="Add New"
                  style={{ textDecoration: 'none' }}
                  to={newLink}
                >
                  New <i className="fas fa-plus fa-fw"></i>
                </Link>
              </span>
            }

            {viewType === 'view-registration' &&
              <>
                {registrationStatus.registrationActive &&
                  <>
                    <span style={{ marginRight: 10, border: "1px solid silver", borderRadius: "0.25em", padding: "0.4em", background: '#eeeeee' }}>
                      <Link
                        className="usa-tooltip usa-button--unstyled"
                        title="Add New"
                        style={{ textDecoration: 'none' }}
                        to={newLink}
                      >
                        New <i className="fas fa-plus fa-fw"></i>
                      </Link>
                    </span>
                    <span style={{ marginRight: 10, border: "1px solid silver", borderRadius: "0.25em", padding: "0.4em", background: '#eeeeee' }}>
                      <Link
                        className="usa-tooltip usa-button--unstyled"
                        title="Add New Guest"
                        style={{ textDecoration: 'none' }}
                        to={newGuestLink}
                      >
                        New Guest For First Selected<i className="fas fa-plus fa-fw"></i>
                      </Link>
                    </span>

                  </>
                }
                <span style={style}>
                  <Link
                    className="usa-tooltip"
                    title="Pay for Selected Registrations"
                    to={newPayment}
                  >
                    Pay For Selected <i className="fas fa-credit-card-front fa-fw"></i>
                  </Link>
                </span>
              </>
            }

            {viewType === 'view-badge' &&
              <>
                <span style={style}>
                  <Link
                    className="usa-tooltip"
                    title="Show Selected Badges"
                    to={printBadges + '&style=show'}
                  >
                    Show Selected <i className="fal fa-id-badge fa-fw"></i>
                  </Link>

                </span>
                <span style={style}>
                  <Link
                    className="usa-tooltip"
                    title="Preview Selected Badges"
                    to={printBadges + '&style=preview'}
                  >
                    Preview Selected <i className="far fa-id-badge fa-fw"></i>
                  </Link>

                </span>
                <span style={style}>
                  <Link
                    className="usa-tooltip"
                    title="Printed Selected Badges"
                    to={printBadges + '&style=print'}
                  >
                    Print Selected <i className="fas fa-id-badge fa-fw"></i>
                  </Link>

                </span>
              </>
            }

            {viewType === 'view-banquet-seating' &&
              <>
                <input
                  className="usa-tooltip"
                  title="Table Size"
                  placeholder="Table Size"
                  value={banquetTableSize}
                  onChange={handleBanquetTableSizeChange}
                  style={{ width: 75 }}
                />
                <span style={style}>
                  <button
                    className="usa-tooltip usa-button--unstyled"
                    title="Assign Tables"
                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                    onClick={() => list.tableAssignments(banquetTableSize)}
                  >
                    Assign Tables<i className="fas fa-chair fa-fw"></i>
                  </button>
                </span>
              </>
            }

            {viewType === 'view-chapter' &&
              <>
                <span style={style}>
                  <button
                    className="usa-tooltip usa-button--unstyled"
                    title="Align Base and Chapter for Selected"
                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                    onClick={() => handleRequestAlignBaseChapter()}
                  >
                    Use Base to Assign Chapter for Selected
                  </button>
                </span>
              </>
            }
          </div>
        </div>
      </div>
    </div >
  )
}

function ConfigTools(props) {
  return (
    <div>
      <div style={{ paddingBottom: 12 }}>
        <div style={{ float: 'left' }}> </div>
        <div style={{ float: 'right' }}>
          <button onClick={props.toggleConfigMode}><i className="fas fa-cog"></i></button>
        </div>
      </div>
    </div>
  )
}
