import React, { Component } from 'react';
import { NavLink, useLocation, Link, Redirect } from "react-router-dom";
import Loading from './Loading';
import ErrorContainer from './ErrorContainer';

import { ContentfulContext } from '../classes/context'
var moment = require('moment');

import ReactMarkdown from 'react-markdown'

class NewsPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      pages: null,
    };
  }

  componentDidMount() {
    const contentfulClient = this.context.contentfulClient

    contentfulClient.getEntries({ 'content_type': 'pageNews', 'order': '-fields.originalCreatedDate', 'limit': 500 })
      .then(entries => {

        this.setState({
          isLoaded: true,
          years: ['2025', '2024', '2023', '2022', '2021', '2020', '2019'],
          pages: entries.items,
        });
      })
      .catch(error => {
        console.log(error)
        this.setState({
          error: error
        })
      })
  }

  render() {

    const { error, isLoaded } = this.state;

    if (error) {
      return <ErrorContainer title="Error" error={error} />

    } else if (isLoaded) {
      if (this.props.pageId || this.props.pageName) {
        return this.renderFullArticle()
      } else if (this.props.year) {
        return this.renderSummaryList(this.props.year)
      }

      return this.renderSummaryList()
    }
    return <Loading title=""
      subtitle="" />;

  }

  renderFullArticle() {

    const { pages } = this.state
    const basicSidebar = true
    const regex = / |\//g
    let page, pageIndex

    if (this.props.pageId) {
      pageIndex = pages.findIndex((page) => (page.sys.id === this.props.pageId));
      page = pages[pageIndex]
    } else if (this.props.pageName) {
      pageIndex = pages.findIndex((page) => (this.props.pageName === (page.fields.headline.toLowerCase().replace(regex, '-'))))
      page = pages[pageIndex]
    }

    return (
      <>
        <SearchRequest />
        <div className="grid-container" style={{ paddingLeft: 0 }}>

          <div className="grid-row">
            <div className="tablet:grid-col-9">
              <main id="main-content">
                <SingleArticle page={page} full={true} />
              </main>
            </div>
            <div className="tablet:grid-col-3">
              {basicSidebar &&
                <div style={{ paddingLeft: 8 }}>
                  <SidebarByYear years={this.state.years} pages={this.state.pages} isLoaded={this.state.isLoaded} />
                  <Sidebar pages={this.state.pages} isLoaded={this.state.isLoaded} />
                </div>
              }
            </div>
          </div>
        </div>
      </>
    )
  }

  renderSummaryList(year) {

    let { pages } = this.state

    let localPages = pages
    let pageTitle, subtitle

    if (year) {
      localPages = pages.filter(page => moment(page.fields.originalCreatedDate).year() == year)
      pageTitle = 'A/TA News from ' + year
    } else {
      localPages = pages.slice(0, 4)
      pageTitle = 'Recent News from A/TA'
      subtitle = 'Select a Year to show more news'
    }

    return (
      <>
        <SearchRequest />
        <div className="grid-container" style={{ paddingLeft: 0 }}>

          <div className="grid-row">
            <div className="tablet:grid-col-9">
              <main id="main-content">
                <section className="usa-section" style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <div className="grid-container margin-y-0">
                    <h2 style={{ marginBottom: 0 }}>{pageTitle}</h2>
                    <div className="usa-prose" >{subtitle}</div>
                  </div>
                </section>
                {localPages.map((page, i) =>
                  <SingleArticle key={i} page={page} summary={true} />
                )
                }
              </main>
            </div>
            <div className="tablet:grid-col-3">
              <div style={{ paddingLeft: 8 }}>
                <SidebarByYear years={this.state.years} pages={this.state.pages} isLoaded={this.state.isLoaded} />
                <Sidebar pages={this.state.pages} isLoaded={this.state.isLoaded} />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }


}

function SearchRequest() {
  const { search, pathname } = useLocation();

  if (!search) { return null }
  const keywords = search.split('=');

  // If there are other parameters on the URL, this means somebody (facebook)
  // added the parameter. Remove.
  if (keywords[0] !== '?search') { return <Redirect to={pathname} /> }

  return <Redirect to={"/?search=" + keywords[1]} />
}

function SingleArticle(props) {
  const { page, summary, full } = props
  const regex = / |\//g

  return (
    <>
      <SearchRequest />
      <section className="usa-section" style={{ paddingTop: 10, paddingBottom: 10 }}>
        <div className="grid-container margin-y-0">
          {summary &&
            <div className="usa-prose">
              <Link
                to={"/news/" + page.fields.headline.toLowerCase().replace(regex, '-')}
                title={page.fields.headline}
                aria-label="news-item"
                className="usa-button--big usa-button--unstyled"
                style={{ textDecoration: 'none', fontSize: 'larger' }}
              >
                {page.fields.headline}
              </Link>
            </div>
          }
          {full &&
            <h2 className="margin-y-0" style={{ paddingBottom: 10 }}>
              {page.fields.headline}</h2>
          }
          <div >
            <strong>{page.fields.author}</strong> {moment(page.fields.originalCreatedDate).from()}
          </div>
          <div className="usa-prose">
            {full &&
              <>
                {page.fields.image && page.fields.image.fields.file &&
                  <img alt={page.fields.headline} style={{ float: 'left', marginBottom: '20px', marginRight: '20px' }} src={page.fields.image.fields.file.url} width="200" />
                }
                <ReactMarkdown escapeHtml={false}>
                  {page.fields.body}
                </ReactMarkdown>
              </>
            }
          </div>
        </div>
      </section>

    </>
  )
}

function Sidebar(props) {

  if (!props.isLoaded) { return null }

  const recentNews = props.pages.slice(0, 5)
  const regex = / |\//g
  return (
    <>
      <h5 className="usa-heading-alt">Recent News</h5>
      <nav aria-label="Secondary navigation">
        <ul className="usa-sidenav">
          {recentNews.map(item => {
            return (
              <li key={item.sys.id} className="usa-sidenav__item">
                <NavLink to={'/news/' + item.fields.headline.toLowerCase().replace(regex, '-')} activeClassName="usa-current">
                  {item.fields.headline}

                </NavLink>
              </li>
            )
          }
          )}
        </ul>
      </nav>
    </>
  )
}

function SidebarByYear(props) {

  if (!props.isLoaded) { return null }

  return (
    <>
      <h5 className="usa-heading-alt">By Year</h5>
      <nav aria-label="Secondary navigation">
        <ul className="usa-sidenav">
          {props.years.map((item) => {
            const count = props.pages.filter(page => { console.log(page.fields.originalCreatedDate); return moment(page.fields.originalCreatedDate).year() == item }).length
            if (!count) { return null }
            return (
              <li key={item.toString()} className="usa-sidenav__item">
                <NavLink to={"/news/year/" + item.toString()} activeClassName="usa-current">
                  {item} ({count})
                </NavLink>
              </li>
            )
          }
          )}
        </ul>
      </nav>
    </>
  )
}

NewsPage.contextType = ContentfulContext
export default NewsPage;
